<template>
  <div class="container" v-if="IsReady">
    <div :class="step !== 0 ? 'border-shadow p-3 px-md-6 mb-3 mb-md-8' : ''">
      <a
        class="btn w-100 text-left d-flex justify-content-between align-items-center rounded-0"
        :class="{ 'px-0': step === 0 }"
        data-toggle="collapse"
        href="#collapseInfo"
        role="button"
        aria-expanded="false"
        aria-controls="collapseInfo"
      >
        <h4>訂購明細</h4>
        <i class="fa fa-chevron-up icon-arrow" aria-hidden="true"></i>
      </a>
      <div :class="step !== 0 ? 'collapse' : ''" :id="step !== 0 ? 'collapseInfo' : ''">
        <!-- 訂購明細 -->
        <div :class="orderDetailClass" :id="step === 0 ? 'collapseInfo' : ''" data-style="2">
          <ul class="cs-table" :class="{ step1: step === 0 }" data-style="6">
            <!-- 標題 -->
            <li class="order__head" :class="{ 'px-4': step !== 0 }">
              <div class="order__head-td">課程</div>
              <div class="order__head-td">課程類型</div>
              <div class="order__head-td">總堂數</div>
              <div class="order__head-td">原價</div>
              <div class="order__head-td">特價</div>
              <div class="order__head-td" v-if="step === 0">刪除</div>
            </li>
            <!-- 購買商品項1 -->
            <li
              v-for="(item, index) in ShoppingData.shoppings" :key="index"
              class="side__box"
              :class="{'active': item.courseType === 2}">
              <div class="side__title" @click="ToggleClass($event)">
                <div class="order__item" :class="{ 'px-4': step !== 0 }">
                  <div class="order__item-td">
                    <i
                      v-if="item.courseType === 2"
                      class="fa fa-chevron-down icon-icon_arrow mr-3" aria-hidden="true">
                    </i>
                    <i
                      v-else
                      class="fa mr-6" aria-hidden="true">
                    </i>
                    <span class="font-weight-bold">{{ item.courseName }}</span>
                  </div>
                  <div  v-if="item.courseType === 3" class="order__item-td order__item--course">
                    {{ item.courseTypeName }}
                  </div>
                  <div v-else class="order__item-td order__item--course">
                    {{ item.courseTypeName }}課程
                  </div>
                  <div
                    v-if="item.courseType === 2"
                    class="order__item-td order__item--class"
                  > - </div>
                  <div
                    v-else
                    class="order__item-td order__item--class"
                  >{{ item.classCount }}</div>
                  <div class="order__item-td order__item--origin">
                    <span class="origin">{{ item.courseOriPrice | toCurrency }}</span>
                  </div>
                  <div class="order__item-td order__item--special">
                    {{ item.coursePrice | toCurrency }}
                  </div>
                  <div class="order__item-td" v-if="step === 0">
                    <a href="" class="btn" @click.prevent="Delete(item.courseId, item.courseName)"
                      ><i class="fa fa-trash fa-lg text-danger" aria-hidden="true"></i
                    ></a>
                  </div>
                </div>
              </div>

              <!-- 購買商品介紹 -->
              <div v-if="item.courseType === 2" class="side__content cs-table" data-style="22">
                <div class="table__head px-6" :class="{ 'pr-md-8': step !== 0 }">
                  <div class="table__head-td">科目(已選數/必選數)</div>
                  <div class="table__head-td">師資</div>
                </div>
                <div
                  class="table__item px-6"
                  :class="{ 'pr-md-8': step !== 0 }"
                  v-for="item2 in item.optionalChooseList"
                  :key="item2.subjectId"
                >
                  <div class="table__item-td">
                    {{ item2.subjectName }}
                    ({{item2.teacherChoose.length}}/{{item2.courseOptionalLimit}})
                  </div>
                  <div class="table__item-td">
                    <el-checkbox-group
                      :disabled="step !== 0"
                      v-model="item2.teacherChoose"
                      :max="item2.courseOptionalLimit">
                      <!-- @change="handleCheckedTeacherChoose(index,index2)" -->
                      <el-checkbox
                        v-for="teacher in item2.optionalTeacherList"
                        :label="teacher.teacherId"
                        :key="`opt${item.courseId}-${teacher.teacherId}`">
                        {{teacher.teacherName}}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 折扣選擇 -->
        <div class="cs-form border-bottom m-4 mx-md-6 pb-3" data-style="3" v-if="step === 0">
          <div class="d-flex  flex-wrap align-items-baseline justify-content-md-between">
            <template v-for="(item, index) in discountList">
              <div
                class="form-check form-check-inline mb-3 w-sm-100"
                v-if="item.discountMode !== 2"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="discountRadios"
                  v-model="chooseIndex"
                  :value="index"
                  :id="'discount' + index"
                  @click="ChooseCode(index)"
                />
                <label class="form-check-label" :for="'discount' + index">
                  {{ item.discountMode === 0 ? item.discountName : '' }}
                  {{ item.discountMode === 1 ? '全館折扣：' + item.discountName : '' }}
                </label>
              </div>

              <div class="form-check form-check-inline discount" v-else :key="index">
                <input
                  class="form-check-input"
                  type="radio"
                  name="discountRadios"
                  v-model="chooseIndex"
                  :value="-1"
                  :id="'discount' + index"
                  @click="ChooseCode(-1)"
                />
                <label class="form-check-label" :for="'discount' + index">折扣碼：</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="inputCode"
                  aria-label="Text input with radio button"
                  :disabled="useCode"
                />
                <button type="button" class="btn border-none delete" @click="UseCode()">
                  <i class="fa fa-check" v-if="useCode === false"></i>
                  <i class="fa fa-trash" v-else></i>
                </button>
              </div>
            </template>
          </div>
        </div>
        <!-- 小計 -->
        <div :class="subtotalClass">
          <div class="row justify-content-end">
            <div class="col-12 col-md-4">
              <div class="cs-table dl-align-right" data-style="7">
                <dl>
                  <dt>小計</dt>
                  <dd>{{ subtotalPrice | toCurrency }} 元</dd>
                </dl>
                <dl>
                  <dt>折扣</dt>
                  <dd v-if="discountValid">-{{ discountPrice | toCurrency }} 元</dd>
                  <dt v-else>未達折扣條件</dt>
                </dl>
                <dl class="h5 text-danger">
                  <dt>總金額</dt>
                  <dd>{{ totalPrice | toCurrency }} 元</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetDiscountList, GetDiscountDetail } from '@/api/ShoppingCartApi';

export default {
  props: ['step', 'ShoppingData', 'IsReady', 'backTotalPrice'],
  data() {
    return {
      // totalPrice: 0,
      // subtotalPrice: 0,
      // discountPrice: 0,
      subtotalClass: 'border-bottom m-4 mx-md-6 pb-3',
      orderDetailClass: 'cs-side collapse show mb-3 w-sm-100 mb-md-8',

      // 折扣選項
      discountList: [],

      // 目前折扣的選擇 -1:是使用折扣碼
      chooseIndex: 0,
      // 使用折扣碼  true表示已經鎖定
      useCode: false,
      // 輸入的折扣碼
      inputCode: '',
      // 使用折扣碼的折扣資料
      UserDiscountData: {
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
      },
      CurrentDiscountData: {
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
      },
    };
  },
  created() {
    if (this.step !== 0) {
      this.subtotalClass = 'px-3 px-md-5';
      this.orderDetailClass = 'cs-side border-bottom py-3 mb-3';
    }
  },
  watch: {
    // 等購物車資料準備好
    async IsReady() {
      if (this.IsReady === true) {
        this._.merge(
          this.CurrentDiscountData,
          this._.pick(this.ShoppingData, this._.keys(this.CurrentDiscountData)),
        );

        if (this.step === 0) await this.GetDiscountList();

        if (this.ShoppingData.discountMode === 1) {
          const index = this._.findIndex(this.discountList, {
            discountCode: this.ShoppingData.discountCode,
          });
          if (index > -1) this.chooseIndex = index;
        }

        if (this.ShoppingData.discountMode === 2) {
          this._.merge(
            this.UserDiscountData,
            this._.pick(this.ShoppingData, this._.keys(this.UserDiscountData)),
          );
          this.chooseIndex = -1;
          this.useCode = true;
          this.inputCode = this.UserDiscountData.discountCode;
        }
      }
    },
  },
  computed: {
    // 小計金額
    subtotalPrice() {
      return this._.reduce(this.ShoppingData.shoppings, (sum, item) => sum + item.coursePrice, 0);
    },
    // 折扣金額
    discountPrice() {
      let discount = 0;
      if (this.ShoppingData.discountType === 1) {
        if (this.subtotalPrice >= this.ShoppingData.discountPrice) {
          discount = this.ShoppingData.discountMoney;
        }
      } else if (this.ShoppingData.discountType === 2) {
        if (this.subtotalPrice >= this.ShoppingData.discountPrice) {
          const val = this.ShoppingData.discountPercent / 100;
          const val2 = Math.ceil(this.subtotalPrice * val);
          discount = this.subtotalPrice - val2;
        }
      }
      return discount;
    },
    // 總金額
    totalPrice() {
      this.$emit('update:backTotalPrice', this.subtotalPrice - this.discountPrice);
      return this.subtotalPrice - this.discountPrice;
    },
    // 使否達到折扣條件
    discountValid() {
      if (this.chooseIndex === 0) return true;
      if (this.UserDiscountData.discountId === 0 && this.chooseIndex === -1) return true;
      if (this.discountPrice > 0) return true;
      this.ClearDiscount();
      this.$emit('update:ShoppingData', this.ShoppingData);
      return false;
    },
  },
  methods: {
    // 明細展開
    ToggleClass(event) {
      if (
        // eslint-disable-next-line operator-linebreak
        event.target.className !== 'fa fa-trash fa-lg text-danger' &&
        event.target.className !== 'btn'
      ) {
        event.currentTarget.parentElement.classList.toggle('active');
      }
    },
    // 設定目前選擇的折扣到購物車資料
    ChooseCode(index) {
      let disCountData = {};
      if (index === -1) disCountData = this._.cloneDeep(this.UserDiscountData);
      else disCountData = this._.cloneDeep(this.discountList[index]);
      this._.merge(this.ShoppingData, disCountData);
      this._.merge(this.CurrentDiscountData, disCountData);
      this.$emit('update:ShoppingData', this.ShoppingData);
    },
    // 清除購物車資料中的折扣資料
    ClearDiscount() {
      const disCountData = {
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
      };
      this._.merge(this.ShoppingData, disCountData);
      this.$emit('update:ShoppingData', this.ShoppingData);
    },
    // 使用折扣碼
    UseCode() {
      if (this.useCode === true) {
        this.inputCode = '';
        this.useCode = false;
        this.UserDiscountData.discountId = 0;
        if (this.chooseIndex === -1) this.ClearDiscount();
      } else {
        this.GetDiscountDetail(this.inputCode);
      }
    },
    // 取得折扣選項
    GetDiscountList() {
      return GetDiscountList().then((response) => {
        if (response.data.statusCode === 0) {
          this.discountList = response.data.data;
        }
      });
    },
    // 取得折扣碼資料
    GetDiscountDetail(code) {
      GetDiscountDetail(code).then((response) => {
        if (response.data.statusCode === 0) {
          if (response.data.data === null) {
            this.$message({
              type: 'error',
              message: '無此折扣碼',
            });
          } else {
            this.useCode = true;
            this.UserDiscountData = this._.cloneDeep(response.data.data);
            this.ChooseCode(-1);
          }
        }
      });
    },
    Delete(id, name) {
      this.$emit('pushDelete', id, name);
    },
  },
};
</script>

<style></style>
